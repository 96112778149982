import React from 'react'
import Css from './Testimonials.module.css';
import Hero from '../../Assets/images/testimonialHero.png'
import {TestimonialsData} from '../../Data/Testimonials.js'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

export const Testimonials = () => {
  return (
    <div className={Css.testimonials}>
        <div className={Css.wrapper}>
            <div className={Css.container}>
            <span>its trendy</span>
            <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. </span>
            </div>
        <img src={Hero} alt="" />
        <div className={Css.wrapper2}>
            <span>3.3M</span>
            <span>thaks for your support guys</span>
        </div>
        </div>

        <div className={Css.carousel}>
            <Swiper
        spaceBetween={50}
        slidesPerView={3}
        loop={true}
        className={Css.tCarousel}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
        >
            {TestimonialsData.map((testimonials , i)=>(
                <SwiperSlide key={i}>
                    <div className={Css.testimonial}>
                        <img src={testimonials.image} alt="" />
                        <span>{testimonials.comment}</span>
                        <hr />
                        <span>{testimonials.name}</span>
                    </div>
                </SwiperSlide>
            ))}
            ...
        </Swiper>
        </div>
    </div>
  )
}
