import React from 'react'
import Css from './Hero.module.css'
import HeroImg from '../../Assets/images/hero.png'
import {RiShoppingBagFill} from 'react-icons/ri'
import { BsArrowRight} from 'react-icons/bs'
import { motion } from "framer-motion"

const Hero = () => {

    const transition = {duration:3,type:"spring"}

  return  (
    <div className={Css.container}>
        <div className={Css.C_sides}>
            <span className={Css.text1}>beauty cream products</span>
            <div className={Css.text2}>
                <span>Trendey collection</span>
                <span>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</span>
            </div>
        </div>
        <div className={Css.mid}>
            <motion.div
            initial={{bottom:"20px"}}
            whileInView={{bottom:"0px"}}
             className={Css.blue_circle}
             transition={transition}
             >
             </motion.div>
            <motion.img 
                initial={{bottom:"20px"}}
                whileInView={{bottom:"0px"}}
                transition={transition}
            src={HeroImg} alt="" width={340} />
            <motion.div 
                initial={{right:"20px"}}
                whileInView={{right:"0px"}}
                transition={transition}
            className={Css.cart}>
                <RiShoppingBagFill />
                <div className={Css.signup}>
                    <span>best signup offers</span>
                    <div>
                        <BsArrowRight />
                    </div>
                </div>
            </motion.div>
        </div>

        <div className={Css.C_sides}>
            <div className={Css.traffic}>
            <span>83k</span>
            <span>monthly Traffic</span>
            </div>
            <div className={Css.customers}>
            <span>500M</span>
            <span>happy customers</span>
            </div>
        </div>
    </div>
  )
}

export default Hero