import  Virtual  from './Components/Virtual/Virtual';
import './App.css'
import Header from './Components/Header/Header';
import Hero from './Components/Hero/Hero';
import Slider from './Components/Slider/Slider';
import Products from './Components/Products/Products';
import { Testimonials } from './Components/Testimonials/Testimonials';
import Footer from './Components/Footer/Footer';

function App(){
  return(<div>
    <Header/>
    <Hero/>
    <Slider />
    <Virtual />
    <Products />
    <Testimonials/>
    <Footer />
  </div>)
}

export default App;