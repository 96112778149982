import React from 'react'
import Css from './Footer.module.css'
import Logo from '../../Assets/images/Logo.png'
import { GoLocation } from 'react-icons/go';
import { IoCallSharp } from 'react-icons/io5';
import { MdAttachEmail } from 'react-icons/md';
import { GoSignIn } from 'react-icons/go';
import { SlPeople } from 'react-icons/sl';
import { AiFillLinkedin } from 'react-icons/ai';

const Footer = () => {
  return (
    <div className={Css.footer}>
        <div className={Css.section1}>
            <img src={Logo} alt="" />
            <span>asian</span>
        </div>
        <div className={Css.section2}>
            <div className={Css.contact}>
                <span>contact </span>
            </div>
            <div className={Css.contact_in}>
                <span><GoLocation/>||| norther part of jarkhand </span>
                <span><IoCallSharp/>955-777-2343</span>
                <span><MdAttachEmail/>e-mail: amandhn5973@gmail.com</span>
            </div>
        </div>
        <div className={Css.section3}>
                <span>account</span>
                <span><GoSignIn/>sin-in</span>
        </div>
        <div className={Css.section3}>
                <span>company</span>
                <span><SlPeople/> student</span>
        </div>
        <div className={Css.section3}>
            <span>resources</span>
            <span><AiFillLinkedin/>https://www.linkedin.com/feed/</span>
        </div>
    </div>
  )
}

export default Footer