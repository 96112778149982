import React from 'react'
import Css from './Virtual.module.css'
import Shade from '../../Assets/images/shade.png'
import ReactCompareImage from 'react-compare-image';
import After from '../../Assets/images/after.png'
import Before from '../../Assets/images/before.png'

const Virtual = () => {
  return (
    <div className={Css.Virtual}>
      <div className={Css.V_left}>
        <span>virtual try on</span>
        <span>never buy teh shit again bcz its loosey these is trendy....</span>
        <span>try now </span>
        <img src={Shade} alt="" />
      </div>
      <div className={Css.V_right}>
        <div className={Css.img_wrapper}>
          <ReactCompareImage leftImage={After} rightImage={Before} />
        </div>
      </div>
    </div>
  )
}

export default Virtual;