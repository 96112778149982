import React from 'react'
import   './Slider.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { SliderProducts } from '../../Data/Products';


const Slider = () => {
  return (
    <div className="slider_container">
            <Swiper
            className='my_swipper'
            modules={[Navigation , Pagination]}
      spaceBetween={50}
      slidesPerView={3}
      loop={true}
      navigation={true}
      pagination={true}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
     {   SliderProducts.map((slide,i)=>(
        <SwiperSlide key={i} >
            <div className="left_slide" >
            <div className='slide_name' >
                <span>{slide.name} </span>
                <span>{slide.detail} </span>
            </div>
            <span>{slide.price}</span>
            <div>shop now buddy</div>
            </div>
            <img src={slide.img} alt="" />
        </SwiperSlide>
     ))}
     ...
    </Swiper>
    </div>
  )
}

export default Slider