import React ,{ useState } from 'react'
import Css from './Products.module.css'
import Plane from '../../Assets/images/plane.png'
import {ProductsData} from '../../Data/Products'
import { useAutoAnimate } from '@formkit/auto-animate/react'

const Products = () => {

    const [parent] = useAutoAnimate()

    const [MenuProducts, setMenuProducts] = useState(ProductsData);

    const filter = (type)=>{
        setMenuProducts(ProductsData.filter((product)=>product.type === type))}

  return (
    <div className={Css.container}>
        <img src={Plane} alt="" />
        <h2>Ours product bbes</h2>

        <div className={Css.products}>
            <ul className={Css.menu_items}>
                <li onClick={()=>setMenuProducts(ProductsData)}>all</li>
                <li onClick={()=>filter("skin care")}>skin care</li>
                <li onClick={()=>filter("conditioner")}>conditioner</li>
                <li onClick={()=>filter("foundation")}>foundation</li>
            </ul>
        <div className={Css.list} ref={parent}>
            {
                MenuProducts.map((product,i)=>(
                    <div className={Css.product} key={i}>
                        <div className="left_slide">
                            <div className="slide_name">
                                <span>{product.name}</span>
                                <span>{product.detail}</span>
                            </div>
                            <span>{product.price}</span>
                            <div>show nnow</div>
                        </div>
                        <img src={product.img} alt="" className='img_products' />
                    </div>
                ))
            }
        </div>
        </div>
    </div>
  )
}

export default Products