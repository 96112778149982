import React from 'react'
import  Css from './Header.module.css'
import Logo from '../../Assets/images/Logo.png'
import {AiFillShopping} from 'react-icons/ai'

const Header = () => {
  return (
    <div className={Css.container}>
        <div className={Css.logo}>
            <img src={Logo} alt=""/>
            <span>Asian</span>
        </div>
        <div className={Css.right}>
            <div className={Css.menu}>
                <ul className={Css.menu}>
                    <li>collections</li>
                    <li>brands</li>
                    <li>New</li>
                    <li>Sales</li>
                    <li>ENG</li>
                </ul>
            </div>
        <input type="text" className={Css.search} placeholder="search" />
        <AiFillShopping className={Css.cart}/>
        </div>
    </div>
  )
}

export default Header
